<template>
  <div class="card card-height">
    <div class="table-responsive">
      <table class="table custom-table-padding">
        <thead>
        <tr>
          <th>Date</th>
          <th>Client Name</th>
          <th>Project Name</th>
          <th>Activity / Task Name</th>
          <th>Task Note</th>
          <th class="text-center">Working Hours</th>
          <th>Work Station</th>
          <th class="text-center">Conveyance Bill</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in tableItems" :key="i">
          <td>{{ item.date }}</td>
          <td>{{ item.full_name }}</td>
          <td>{{ item.project_name }}</td>
          <td>{{ item.project_account_name }}</td>
          <td>{{ item.full_description }}</td>
          <td class="text-center">{{ item.hours }}</td>
          <td>{{ item.area_name }}</td>
          <td class="text-center">{{ commaFormat(item.conveyance) }}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th colspan="5" class="text-center">Total</th>
          <th class="text-center">{{commaFormat(totalSum('hours'))}}</th>
          <th></th>
          <th class="text-center">{{commaFormat(totalSum('conveyance'))}}</th>
          <th></th>
        </tr>
        </tfoot>
      </table>
      <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import figureFormatter from "@/services/utils/figureFormatter";
const props = defineProps({
  tableItems: {
    type: Array,
    default: []
  }
})

const {commaFormat} = figureFormatter()
const totalSum = (column) => {
 return props.tableItems.reduce((total, i) => total + parseFloat(i[column]), 0);
}
</script>
